import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";

import Header from "./Header.js";
import DashboardRow from "./DashboardRow.js";
import AllocatedHrs from "./components/AllocatedHrs.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      depot: "Select a Depot",
      dataRows: <div className="mt-3">Select a depot to start!</div>,
      results: []
    };
    //this.performSearch(); 
  }
  

  manageRows(results) {
  var dataRowsHtml = [];
          
  results.forEach(tsheetData => {
    //console.log(tsheetData.job);
    tsheetData.bgLine = "";
    
      tsheetData.percentDone > 95
        ? (tsheetData.bgLine = "bg-danger")
        : tsheetData.percentDone > 80
        ? (tsheetData.bgLine = "bg-warning")
        : (tsheetData.bgLine = "bg");
    
    dataRowsHtml.push(<DashboardRow tsheetData={tsheetData} />);
  });


if (dataRowsHtml.length === 0) {
  this.setState({dataRows: <div className="mt-3">No active jobs were found!</div>})
} else {
  this.setState({ dataRows: dataRowsHtml });
}
};


  performSearch() {
    let depotCode = "";
    switch (this.state.depot) {
      case "Victoria":
        depotCode = "vic";
        break;
      case "Queensland":
        depotCode = "qld";
        break;  
      default:
        depotCode = "all";
        break;
    }

    const urlString = process.env.REACT_APP_BACKEND_URL + depotCode;

    $.ajax({
      url: urlString,
      success: searchResults => {
        console.log("success fetch data!");
        const results = searchResults;
        
        this.manageRows(results);
        
      },
      error: (xhr, status, err) => {
        this.setState({dataRows: <div className="mt-3">Failed to fetch data!</div>})
        console.log("Failed to fetch data!");
      }
    });
  }

  handleDepot = (depot) => {
    this.setState({depot, dataRows:<div className="spinner-grow mt-4" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>})
    setTimeout(() => {
      this.performSearch();
    }, 1);                        
    
    setInterval(this.performSearch.bind(this), process.env.REACT_APP_SET_REFRESH_PAGE_INTERVAL); // 60000 = 1min // 600000 = 10 min
  }

  componentDidUpdate() {
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Header depot={this.state.depot} handleDepot={this.handleDepot}/>
          <div className="container-fluid">
            <div className="row mt-3">
              <div className="col-12">
                
                  <Switch>
                    <Route
                      exact
                      path="/AllocatedHrs/:id"
                      component={AllocatedHrs}
                    />
                    <Route path="/">
                    <div className="mb-12 card  bg-dark">
                      <table className="table table-striped table-dark">
                        <thead>
                          <tr>
                            <th scope="col">Job Nº</th>
                            <th scope="col">Allocated Hours</th>
                            <th scope="col">Completion Date</th>
                            <th scope="col">Count Time</th>
                            <th scope="col">%</th>
                            <th scope="col">Who’s Working</th>
                          </tr>
                        </thead>
                        <tbody>{this.state.dataRows}</tbody>
                      </table>
                     </div> 
                    </Route>
                  </Switch>
                
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }

  componentDidMount() {
   // this.performSearch();
   // setInterval(this.performSearch.bind(this), process.env.REACT_APP_SET_REFRESH_PAGE_INTERVAL); // 60000 = 1min // 600000 = 10 min
  }
}

export default App;
