import React from "react";
import { Link } from "react-router-dom";

import Clock from "react-live-clock";

import { Icon } from "@iconify/react";
import fullscreenOutline from "@iconify/react/ant-design/fullscreen-outline";
import fullscreenExitOutline from "@iconify/react/ant-design/fullscreen-exit-outline";

class Header extends React.Component {
  toggleFullScreen() {
    if (!document.documentElement.requestFullscreen()) {
      document.documentElement.requestFullscreen();
    } else {
      document.webkitExitFullscreen();
    }
  }

  changeDepot(depot) {
    this.props.handleDepot(depot)
  }

  render() {
    return (
      <div className="container-fluid py-3 align-middle" id="navbar">
        <div className="row">
          <div className="col-2 pt-4 text-secondary rounded clock">
            <div className="border clock-content">
              <Clock
                format={"HH:mm:ss"}
                ticking={true}
                timezone={this.props.depot === "Victoria" ? "Australia/Victoria" : "Australia/Brisbane"}
              />
            </div>
          </div>
          <div className="col-2"></div>
          <div className="col-4">
            <Link to={"/"} className="nav-link">
              <img
                alt="STG Logo"
                src="./img/logo-stg.png"
                className="stgLogo"
              />
            </Link>
          </div>
          <div className="col-2 mt-5 text-center">
            <div className="input-group-prepend text-center">
              <button type="button" className="btn btn-outline-secondary w-100 color-white">{this.props.depot}</button>
              <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu">
                <span className="dropdown-item" onClick={() => this.changeDepot("Queensland")} className="cursorpointer d-block ml-2 mb-2" id="qld">Queensland</span>
                <span className="dropdown-item" onClick={() => this.changeDepot("Victoria")}  className="cursorpointer d-block ml-2 mb-2" id="vic">Victoria</span>
                <span className="dropdown-item" onClick={() => this.changeDepot("All Depots")}  className="cursorpointer d-block ml-2" id="all">All Depots</span>
              </div>
            </div>
          </div>
          <div className="col-2 text-right zoomIco">
            <Icon
              icon={fullscreenOutline}
              className="text-secondary"
              onClick={this.toggleFullScreen}
            />
            <Icon
              icon={fullscreenExitOutline}
              className="text-secondary d-none"
              onClick={this.toggleFullScreen}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
